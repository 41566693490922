:root {
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 16px;
  --spacing-4: 32px;
  --spacing-5: 48px;

  --border-color: rgba(217, 212, 173, 0.3);
  --bg-color: rgba(217, 212, 173, 0.1);
  --bg-color-hover: rgba(217, 212, 173, 0.2);

  --error-color: #ff2323;

  --condition-indent: var(--spacing-3);
}

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

body {
  font-family: var(--font-body);
  font-size: 16px;
  color: white;
  min-height: 100vh;
  background: url(../public/zodiac-bg.svg) 0% 0% / cover fixed;
  margin: 0px;
}
body::before {
  content: " ";
  display: block;
  position: fixed;
  z-index: -9999;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    108.86deg,
    rgb(12 16 32 / 85%) 6.24%,
    rgb(8 14 5 / 85%) 53.08%,
    rgb(24 4 3 / 85%) 96.54%
  );
}

a {
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  color: rgba(252, 248, 217, 0.8);
}

h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 73px;
  text-align: center;
  margin: 0;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}

h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

p {
  margin: 0;
}

input:not([type="checkbox"]),
textarea,
select {
  font-size: 14px;
  background-color: transparent;
  font-family: var(--font-mono), monospace;
  color: white;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(217, 212, 173, 0.8);
  line-height: 20px;
  padding: var(--spacing-2) 10px;
  height: 38px;
  transition: all 0.1s ease;
}

input:hover:enabled,
textarea:hover:enabled select:hover:enabled {
  border-color: white;
}

input:active,
textarea:active,
select:active,
input:focus,
textarea:focus,
select:focus {
  border: 1px solid white;
  outline: none;
}

option {
  padding: 0;
}

code {
  font-family: var(--font-mono), monospace;
  padding: 4px 8px;
  background: #3e3d2ad1;
  border-radius: 4px;
  border: 1px solid rgba(217, 212, 173, 0.3);
}

/* Works on Firefox*/
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(217, 212, 173, 0.8) rgba(217, 212, 173, 0.1);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(217, 212, 173, 0.3);
  border-radius: 0px;
  cursor: pointer;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(217, 212, 173, 0.5);
  border-radius: 0px;
}
